import "./styles.css";
let About = () => {
    return (
        <section id="About">
            <div className="skills-head flex flex-row justify-center mb-14">
                <p>{"<About />"}</p>
            </div>
            <div className="flex flex-col about gap-4">
                <p>Hello Mates i reside in Mumbai</p>
                <p>I am a student at VJTI Mumbai pursing BTech in IT</p>
                <p>I am Currently building cool stuffs like fullstack projects</p>
                <p>Currently learning full stack and DSA </p>
                <p>I Like to actively participate in group projects and produce necessary outcomes</p>

            </div>
        </section>
    )
}
export default About;